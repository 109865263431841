var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "fix-container" }, [
        _c(
          "div",
          { staticClass: "title-container" },
          [
            _c("el-button", {
              attrs: { icon: _vm.triggerIcon, type: "text" },
              on: { click: _vm.trigger },
            }),
            _c(
              "span",
              { staticClass: "tittle", attrs: { hidden: !_vm.show } },
              [_vm._v(_vm._s(_vm.title))]
            ),
            _c("el-button", {
              attrs: {
                hidden: !_vm.show,
                icon: "el-icon-refresh",
                type: "text",
              },
              on: { click: _vm.reset },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "search-container", attrs: { hidden: !_vm.show } },
          [
            _c("el-input", {
              attrs: {
                placeholder: _vm.placeholder,
                clearable: "",
                "suffix-icon": "el-icon-search",
              },
              model: {
                value: _vm.filterText,
                callback: function ($$v) {
                  _vm.filterText = $$v
                },
                expression: "filterText",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-scrollbar",
        {
          staticClass: "tree-style",
          attrs: {
            "wrap-class": "tree-scrollbar",
            native: false,
            "view-class": "tree-scrollr-container",
            noresize: false,
            tag: "div",
          },
        },
        [
          _c(
            "div",
            {
              ref: "elTree",
              staticClass: "grid-content",
              attrs: { hidden: !_vm.show },
            },
            [
              _c(
                "div",
                { attrs: { hidden: !_vm.isNode } },
                [
                  _c("el-tree", {
                    staticClass: "filter-tree",
                    attrs: {
                      data: _vm.treeData,
                      props: _vm.defaultProps,
                      "highlight-current": "",
                      load: _vm.loadNode,
                      lazy: true,
                      indent: 2,
                      "default-expand-all": false,
                      "expand-on-click-node": false,
                      "node-key": "id",
                    },
                    on: { "node-click": _vm.handleCheckChange },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var node = ref.node
                          return _c(
                            "span",
                            { staticClass: "el-tree-node__label" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    disabled: _vm.calWordWidth(node.label),
                                    effect: "dark",
                                    content: node.label,
                                    placement: "right",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tree_label_text" },
                                    [_vm._v(_vm._s(node.label))]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { attrs: { hidden: _vm.isNode } },
                [
                  _c("el-tree", {
                    staticClass: "filter-tree",
                    attrs: {
                      data: _vm.treeData,
                      props: _vm.defaultProps,
                      "highlight-current": "",
                      "default-expand-all": true,
                      "expand-on-click-node": false,
                      "node-key": "id",
                      indent: 2,
                    },
                    on: { "node-click": _vm.handleCheckChange },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var node = ref.node
                          return _c(
                            "span",
                            { staticClass: "el-tree-node__label" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    disabled: _vm.calWordWidth(node.label),
                                    effect: "dark",
                                    content: node.label,
                                    placement: "right",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tree_label_text" },
                                    [_vm._v(_vm._s(node.label))]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }